import React, { Fragment, useCallback } from "react";

import Logo from "@/src/components/sembly-ui/components/Logo/Logo";
import useMedia from "@/src/hooks/useMedia";

interface Link {
  label: string;
  href: string;
  openNewTab?: boolean;
}

export type FooterProps = {
  leftLinks: Link[];
  rightLinks: Link[];
  headerLabel: string | JSX.Element;
  contactDetail: string | JSX.Element;
  copyrightLabel: string | JSX.Element;
  cities?: string[];
};

const Footer: React.FC<React.PropsWithChildren<FooterProps>> = ({
  leftLinks = [],
  rightLinks = [],
  headerLabel,
  contactDetail,
  copyrightLabel,
  cities,
}) => {
  const { matchesMobile } = useMedia();

  const mapLink = useCallback((data: Link, idx: number) => {
    const linkProps = data.openNewTab
      ? {
          target: "_blank",
          rel: "noreferrer",
        }
      : {};
    return (
      <li key={idx} className="text-xs font-medium sm:text-base">
        <a href={data.href} className="hover:underline" {...linkProps}>
          {data.label}
        </a>
      </li>
    );
  }, []);

  return (
    <footer className="relative bg-dark-purple-100 px-20 py-32 text-white sm:p-30">
      <div className="mx-auto flex w-full max-w-[1200px] flex-1 flex-wrap items-center justify-between">
        <div className="flex flex-col">
          <div className="mb-4 flex sm:mb-0">
            <Logo size={matchesMobile ? "m" : "l"} href="/" withBetaText={false} />
          </div>
          <div className="max-w-[211px] font-robotoSlab text-base sm:max-w-full sm:text-xl md:leading-[48px]">
            {headerLabel}
          </div>
        </div>
        <div className="mt-20 flex w-full space-x-24 sm:space-x-[44px] md:mt-0 md:w-[375px] md:justify-end">
          <ul className="space-y-8 border-r border-gray-60 pr-24 sm:pr-[44px]">{leftLinks.map(mapLink)}</ul>
          <ul className="space-y-8">{rightLinks.map(mapLink)}</ul>
        </div>
      </div>
      <div className="mx-auto mt-24 flex w-full max-w-[1200px] flex-wrap items-center justify-between md:mt-32 md:flex-nowrap">
        <div className="flex w-full flex-wrap space-y-4 text-xs sm:space-x-32 sm:space-y-0 sm:text-base">
          <div>{copyrightLabel}</div>
          {cities && (
            <div>
              {cities.map((city, idx) => (
                <Fragment key={city}>
                  <span>{city}</span>
                  {/* pipe */}
                  {idx !== cities.length - 1 && <span className="mx-8">&#124;</span>}
                </Fragment>
              ))}
            </div>
          )}
        </div>
        <div className="mt-8 text-xs sm:mt-0 sm:text-base md:min-w-[375px]">{contactDetail}</div>
      </div>
    </footer>
  );
};

Footer.displayName = "Footer";
export default Footer;
