import React from "react";

import Head from "next/head";

import { APP_DESCRIPTION, APP_TITLE } from "@/src/constants/AppConstant";
import { SNIPPET_TYPE } from "@/src/constants/ServerConstant";
import { generateSnippetLink } from "@/src/utils/SEO/MetaTagsHelper";

export type MetaTagsProps = {
  title?: string;
  description?: string;
  image?: {
    url: string;
    width?: number;
    height?: number;
  };
  children?: React.ReactNode;
};

// https://developers.google.com/search/docs/appearance/site-names
const jsonLDSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "Sembly",
  url: "https://sembly.com/",
};

const MetaTags: React.FC<MetaTagsProps> = ({
  title = APP_TITLE,
  description = APP_DESCRIPTION,
  image = {
    url: generateSnippetLink({ snippetType: SNIPPET_TYPE.APP }),
    width: 1200,
    height: 630,
  },
  children,
}) => {
  return (
    <Head>
      <title key="title">{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={"Sembly"} />
      <meta property="og:image" content={image?.url} />
      {image?.width && <meta property="og:image:width" content={`${image?.width}`} />}
      {image?.height && <meta property="og:image:height" content={`${image?.height}`} />}
      <meta property="twitter:card" content={"summary_large_image"} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLDSchema) }} />
      {children}
    </Head>
  );
};

MetaTags.displayName = "MetaTags";

export default MetaTags;
