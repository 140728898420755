import type { PropsWithChildren } from "react";
import React from "react";

import styled from "styled-components";

import { usePublicPageWrapperContext } from "@/src/components/container/PublicPageWrapper/PublicPageWrapperContext";
import { colors } from "@/src/components/sembly-ui/core/token";

const Wrapper = styled.div.attrs(() => ({
  className: "h-screen overflow-y-auto relative z-20",
}))<{ shouldUseSnap?: boolean }>`
  ${({ shouldUseSnap }) => (shouldUseSnap ? "scroll-snap-type: y" : "")};

  height: 100dvh;
  overflow-y: overlay;

  // style to match the scrollbar style in app
  // for firefox scroll style
  scrollbar-color: ${colors.gray[80]}B3 transparent;
  // for chrome scroll style
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.gray[80]}B3; // opacity 70%
    border-radius: 24px;
  }
`;

export default function PageWrapper({ children }: PropsWithChildren<{}>) {
  const { pageViewWrapperRef } = usePublicPageWrapperContext();

  // TODO: investigate why sticky scroll glitchy
  const shouldUseSnap = false;
  // for auto-scroll to next section, only work properly on chrome
  // const shouldUseSnap = matchesDesktop && isChromium;

  return (
    <Wrapper ref={pageViewWrapperRef} shouldUseSnap={shouldUseSnap}>
      {children}
    </Wrapper>
  );
}
