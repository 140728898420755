import React, { useCallback, useEffect, useState } from "react";

import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { BrowserRouter, matchPath } from "react-router-dom";

import LoginDialog from "@/src/components/container/HeaderContainer/LoginDialog";
import PublicHeader from "@/src/components/sembly-ui/components/PublicHeader/PublicHeader";
import {
  ROUTER_PATH_ABOUT_PAGE_PUBLIC,
  ROUTER_PATH_HOME,
  ROUTER_PATH_LOGIN,
  ROUTER_PATH_PASSWORD_FORGOT,
  ROUTER_PATH_SIGNUP,
} from "@/src/constants/AppConstant";
import UserCookieConsentDialog from "@/src/domains/user/components/UserSettings/components/cookie/UserCookieConsentDialog";
import { SHOW_COOKIE_NOTICE } from "@/src/domains/user/components/UserSettings/constant/UserSettingsConstant";
import { useHeaderModal } from "@/src/hooks/useHeaderModal";
import useSyncAuthCookies from "@/src/hooks/useSyncAuthCookies";
import AuthenticationDialog from "@/src/pages/LandingPage/FormDialog/AuthenticationDialog";
import EmailVerificationDialog from "@/src/pages/LandingPage/FormDialog/EmailVerificationDialog";
import { useLoginDialog, useLoginDialogActions } from "@/src/stores/dynamic/loginDialog";

export type HeaderContainerProps = {
  withHeaderActions?: boolean;
};

const HeaderContainer: React.FC<React.PropsWithChildren<HeaderContainerProps>> = ({ withHeaderActions = true }) => {
  useSyncAuthCookies(true);
  const [t] = useTranslation("landingpage");

  const [headerModalContext, setHeaderModalContext] = useHeaderModal();
  const router = useRouter();

  // Login and forgot password form are combine for seamless switch without modal close and re-open
  const isLoginDialogOpened = useLoginDialog(state => state.open);
  const { closeLoginDialog, openLoginDialog } = useLoginDialogActions();
  const setLoginDialogOpened = useCallback(
    (state: boolean) => {
      if (state) openLoginDialog();
      else closeLoginDialog();
    },
    [closeLoginDialog, openLoginDialog],
  );

  useEffect(() => {
    Boolean([ROUTER_PATH_PASSWORD_FORGOT, ROUTER_PATH_LOGIN].includes(router.pathname));
  });

  const [isSignupDialogOpened, setSignupDialogOpened] = useState(false);

  useEffect(() => {
    // removing `isInvitationTokenEmpty` from the dialog open logic since if the userState is "ADMITTED", they can login without invitation token.
    setSignupDialogOpened(Boolean(router.pathname === ROUTER_PATH_SIGNUP));
  }, [router.pathname]);

  useEffect(() => {
    switch (headerModalContext) {
      case "LOGIN": {
        setLoginDialogOpened(true);
        break;
      }
      case "SIGN_UP": {
        setSignupDialogOpened(true);
        break;
      }
    }
    setHeaderModalContext("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerModalContext, setHeaderModalContext]);

  const isInAboutSemblyPage = matchPath(location.pathname, ROUTER_PATH_ABOUT_PAGE_PUBLIC);
  const generateLinkButtonLabel = () => {
    if (isInAboutSemblyPage) return t("publicHeader.home");
    else return t("publicHeader.about");
  };

  const publicHeaderProps = {
    leftButtonLabel: t("publicHeader.login"),
    handleLeftButtonClick: () => {
      setLoginDialogOpened(true);
    },
    rightButtonLabel: t("publicHeader.signUp"),
    handleRightButtonClick: () => {
      setSignupDialogOpened(true);
    },
    linkButtonLabel: generateLinkButtonLabel(),
    linkButtonHref: isInAboutSemblyPage ? ROUTER_PATH_HOME : ROUTER_PATH_ABOUT_PAGE_PUBLIC,
    withHeaderActions,
  };

  const loginDialogProps = {
    handleClose: () => setLoginDialogOpened(false),
    open: isLoginDialogOpened,
  };

  const signupDialogProps = {
    onClose: () => {
      setSignupDialogOpened(false);
      if (router.pathname === ROUTER_PATH_SIGNUP) {
        router.push(ROUTER_PATH_HOME);
      }
    },
    open: isSignupDialogOpened,
    isLandingPage: true,
  };

  // Import BrowserRouter to be able to use useHistory and useLocation
  return (
    // @ts-ignore @types/react require explicitly set children as props
    <BrowserRouter>
      <PublicHeader {...publicHeaderProps} />
      {isLoginDialogOpened && <LoginDialog {...loginDialogProps} />}
      {isSignupDialogOpened && <AuthenticationDialog {...signupDialogProps} />}
      <EmailVerificationDialog />
      {SHOW_COOKIE_NOTICE && !isSignupDialogOpened && !isLoginDialogOpened && <UserCookieConsentDialog />}
    </BrowserRouter>
  );
};

HeaderContainer.displayName = "HeaderContainer";

export default HeaderContainer;
