import type { FC, PropsWithChildren } from "react";
import { createContext, useContext, useState } from "react";

import noop from "@/src/utils/helpers/noop";

const EarlyAccessContext = createContext({
  email: "",
  setEmail: (email: string) => {},
  clearEmail: noop,
});

interface UseEarlyAccess {
  email: string;
  setEmail(email: string): void;
  clearEmail(): void;
}

function useEarlyAccess(): UseEarlyAccess {
  const context = useContext(EarlyAccessContext);

  if (!context) {
    throw new Error("useEarlyAccess must be used within a EarlyAccessProvider");
  }
  return context;
}

const EarlyAccessProvider: FC<PropsWithChildren<{}>> = props => {
  const [email, setEmail] = useState("");
  const provider = {
    email,
    setEmail,
    clearEmail: () => setEmail(""),
  };
  return <EarlyAccessContext.Provider value={provider} {...props} />;
};

export { useEarlyAccess, EarlyAccessProvider };
