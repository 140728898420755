import React from "react";

import { BrowserRouter } from "react-router-dom";

import Footer from "@/src/components/container/FooterContainer/FooterContainer";
import Header from "@/src/components/container/HeaderContainer/HeaderContainer";
import { withPublicPageContext } from "@/src/components/container/PublicPageWrapper/PublicPageWrapperContext";
import PageWrapper from "@/src/components/container/PublicPageWrapper/components/PageWrapper";
import ReduxProviderWrapper from "@/src/core/ReduxProviderWrapper";
import UserSuspended from "@/src/domains/moderation/components/SuspendedDialog/UserSuspended/UserSuspended";
import { SignupDialog } from "@/src/domains/user/components/SignupDialog/SignupDialog";
import { AuthProvider } from "@/src/hooks/authentication/AuthProvider";
import { EarlyAccessProvider } from "@/src/hooks/useEarlyAccess";
import { HeaderModalProvider } from "@/src/hooks/useHeaderModal";

export type PublicPageWrapperProps = {
  withHeaderActions?: boolean;
  className?: string;
};

const PublicPageWrapper: React.FC<React.PropsWithChildren<PublicPageWrapperProps>> = ({
  children,
  className = "bg-white",
  withHeaderActions = true,
}) => {
  return (
    <div className="font-roboto">
      <EarlyAccessProvider>
        <PageWrapper>
          <HeaderModalProvider>
            <ReduxProviderWrapper>
              <AuthProvider>
                <Header withHeaderActions={withHeaderActions} />
                {/* Import BrowserRouter to be able to use useHistory and useLocation, used in sign up dialog */}
                {/* @ts-ignore @types/react require explicitly set children as props */}
                <BrowserRouter>
                  <main className={className}>{children}</main>
                  <Footer />
                  <SignupDialog />
                  <UserSuspended />
                </BrowserRouter>
              </AuthProvider>
            </ReduxProviderWrapper>
          </HeaderModalProvider>
        </PageWrapper>
      </EarlyAccessProvider>
    </div>
  );
};

PublicPageWrapper.displayName = "PublicPageWrapper";

export default withPublicPageContext(PublicPageWrapper);
