import React from "react";

import { useTranslation } from "react-i18next";

import Footer from "@/src/components/sembly-ui/components/Footer/Footer";
import {
  APP_GENERAL_EMAIL,
  APP_NAME,
  ROUTER_PATH_ABOUT_PAGE_PUBLIC,
  ROUTER_PATH_CAREERS_PAGE,
  ROUTER_PATH_COMMUNITY_CONTENT_POLICY,
  ROUTER_PATH_PRIVACY_POLICY_PAGE,
  ROUTER_PATH_TERMS_OF_SERVICE_PAGE,
} from "@/src/constants/AppConstant";

const FooterContainer: React.FC<React.PropsWithChildren<{}>> = () => {
  const [t] = useTranslation("landingpage");
  const currentYear = new Date().getFullYear();

  const footerProps = {
    leftLinks: [
      { label: t("publicFooter.aboutCompany", { app_name: APP_NAME }), href: ROUTER_PATH_ABOUT_PAGE_PUBLIC },
      { label: t("publicFooter.careers"), href: ROUTER_PATH_CAREERS_PAGE },
      // TODO: @jaco to enable when ready, also don't forget to change width in Footer.tsx
      // { label: t("publicFooter.cookieSettings"), href: ROUTER_PATH_PRIVACY_POLICY_PAGE },
    ],
    rightLinks: [
      { label: t("publicFooter.termsOfService"), href: ROUTER_PATH_TERMS_OF_SERVICE_PAGE, openNewTab: true },
      { label: t("publicFooter.privacyPolicy"), href: ROUTER_PATH_PRIVACY_POLICY_PAGE, openNewTab: true },
      { label: t("publicFooter.communityContentPolicy"), href: ROUTER_PATH_COMMUNITY_CONTENT_POLICY, openNewTab: true },
    ],
    headerLabel: t("publicFooter.slogan"),
    contactDetail: (
      <p className="whitespace-nowrap">
        {t("publicFooter.inquiries")}{" "}
        <a href={`mailto:${APP_GENERAL_EMAIL}`} className="underline">
          {APP_GENERAL_EMAIL}
        </a>
      </p>
    ),
    copyrightLabel: t("publicFooter.copyright", { app_name: APP_NAME, year: currentYear }),
    cities: [
      t("publicFooter.cities.singapore"),
      t("publicFooter.cities.sanFrancisco"),
      t("publicFooter.cities.newDelhi"),
      t("publicFooter.cities.jakarta"),
      t("publicFooter.cities.tallinn"),
    ],
  };

  return <Footer {...footerProps} />;
};

FooterContainer.displayName = "FooterContainer";

export default FooterContainer;
