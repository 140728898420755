// TODO Refactor this to use i18n

/* APP CONFIGURATION */
import getConfig from "next/config";

import { APP_DESCRIPTION, APP_NAME } from "@/src/constants/AppConstant";

const { publicRuntimeConfig } = getConfig();

export const APP_PORT = 3000;

/* SERVER PATH CONFIGURATION */
export const APP_PATH = publicRuntimeConfig.APP_PATH || `http://localhost:${APP_PORT}`;
export const SERVER_PATH = publicRuntimeConfig.APP_SERVER_PATH || "http://localhost:8082";

export const IMAGE_SNIPPET_TYPE_LANDSCAPE = "landscape";
export const IMAGE_SNIPPET_TYPE_SQUARE = "square";

export const SNIPPET_TYPE = {
  APP: "APP",
  SPACE: "SPACE",
  NODE: "NODE",
};

export const SNIPPET_SIZE = {
  LANDSCAPE: "LANDSCAPE",
  SQUARE: "SQUARE",
};

/* IMAGE SIZE CONFIGURATION */
export const LANDSCAPE_IMAGE_WIDTH = "1200";
export const LANDSCAPE_IMAGE_HEIGHT = "630";

export const SQUARE_IMAGE_WIDTH = "630";
export const SQUARE_IMAGE_HEIGHT = "630";

/* PAGE CONFIGURATION */
export const HOME_PAGE_TITLE = APP_NAME;
export const HOME_PAGE_DESCRIPTION = APP_DESCRIPTION;

export const LOG_IN_PAGE_TITLE = `${APP_NAME} - Log In`;
export const LOG_IN_PAGE_DESCRIPTION = APP_DESCRIPTION;

export const SIGN_UP_PAGE_TITLE = `${APP_NAME} - Sign Up`;
export const SIGN_UP_PAGE_DESCRIPTION = APP_DESCRIPTION;

export const FAQ_PAGE_TITLE = `${APP_NAME} - Frequently Asked Questions`;
export const FAQ_PAGE_DESCRIPTION = APP_DESCRIPTION;

export const ABOUT_PAGE_TITLE = `${APP_NAME} - About`;
export const ABOUT_PAGE_DESCRIPTION = APP_DESCRIPTION;

export const CAREERS_PAGE_TITTLE = `${APP_NAME} - Careers`;
export const CAREERS_PAGE_DESCRIPTION = APP_DESCRIPTION;

export const PRIVACY_POLICY_PAGE_TITLE = `${APP_NAME} - Privacy Policy`;
export const PRIVACY_POLICY_PAGE_DESCRIPTION = APP_DESCRIPTION;

export const TERMS_OF_SERVICE_PAGE_TITLE = `${APP_NAME} - Terms of Service`;
export const TERMS_OF_SERVICE_PAGE_DESCRIPTION = APP_DESCRIPTION;

export const GENERAL_INVITE_PAGE_TITLE = "{{name}} has invited you to join Sembly!";
export const GENERAL_INVITE_PAGE_DESCRIPTION = APP_DESCRIPTION;
export const SPACE_INVITE_PAGE_TITLE = "{{name}} has invited you to join the {{spaceName}} community on Sembly!";

export const NODE_PAGE_DEFAULT_TITLE = "Ongoing discussion on Sembly";

export const NODE_PAGE_VERIFIED_CLAIM_TITLE = "Verified claim on Sembly";
export const NODE_PAGE_REASONABLY_GROUNDED_CLAIM_TITLE = "Ongoing discussion on Sembly";
export const NODE_PAGE_UNDER_DEBATE_CLAIM_TITLE = "Ongoing discussion on Sembly";
export const NODE_PAGE_CONTESTED_CLAIM_TITLE = "Ongoing discussion on Sembly";
export const NODE_PAGE_SEEKING_MORE_INPUT_CLAIM_TITLE = "Ongoing discussion on Sembly";
export const NODE_PAGE_MARKED_ACCURATE_CLAIM_TITLE = "Ongoing discussion on Sembly";
export const NODE_PAGE_MARKED_INACCURATE_CLAIM_TITLE = "Ongoing discussion on Sembly";
//@TODO export const NODE_PAGE_DOUBTED_CLAIM_TITLE = "d";
export const NODE_PAGE_DISPROVEN_CLAIM_TITLE = "Disproven claim on Sembly";
export const NODE_PAGE_VERIFIED_ANSWERS_CLAIM_TITLE = "Ongoing discussion on Sembly";
export const NODE_PAGE_PROPOSED_ANSWERS_CLAIM_TITLE = "Ongoing discussion on Sembly";
export const NODE_PAGE_REASONABLY_GROUNDED_ANSWERS_CLAIM_TITLE = "Ongoing discussion on Sembly";
export const NODE_PAGE_VERIFIED_CONTESTED_CLAIM_TITLE = "Verified claim on Sembly";
//@TODO export const NODE_PAGE_LOW_CREDIBILITY_SOURCE_CLAIM_TITLE = "l";
export const NODE_PAGE_NEEDS_REVIEW_CLAIM_TITLE = "Ongoing discussion on Sembly";
export const NODE_PAGE_HAS_INACCURACIES_CLAIM_TITLE = "Ongoing discussion on Sembly";

export const NODE_PAGE_DESCRIPTION = "Join the discussion on {{spaceName}}.";

export const REDIS_GROUP = {
  SHORT_LINK: "shortlink",
};
