import React from "react";

import Logo from "@/src/components/sembly-ui/components/Logo/Logo";
import { LinkButton } from "@/src/components/sembly-ui/core";
import Button from "@/src/components/sembly-ui/core/BaseComponent/Button/LegacyButton";
import useMedia from "@/src/hooks/useMedia";

export type PublicHeaderProps = {
  handleLeftButtonClick: () => void;
  handleRightButtonClick?: () => void;
  linkButtonHref?: string;
  leftButtonLabel: string;
  rightButtonLabel?: string;
  linkButtonLabel?: string;
  withHeaderActions?: boolean;
};

const PublicHeader: React.FC<React.PropsWithChildren<PublicHeaderProps>> = ({
  leftButtonLabel,
  handleLeftButtonClick,
  rightButtonLabel,
  handleRightButtonClick,
  linkButtonLabel,
  linkButtonHref,
  withHeaderActions = true,
}) => {
  const { matchesMobile } = useMedia();
  const buttonClassName = "!rounded-[5px] !px-16 sm:min-w-[100px] h-[29px] sm:h-auto";

  return (
    <header className="fixed left-0 top-0 z-20 flex h-[48px] w-full bg-white px-20 md:h-[56px]">
      <div className="relative mx-auto flex w-full max-w-[1368px] items-center justify-between">
        <div className="flex items-center space-x-20">
          <Logo color="purple" href="/" />
        </div>
        {withHeaderActions && (
          <nav className="flex flex-1 items-center justify-end space-x-4">
            {linkButtonLabel && (
              <LinkButton
                href={linkButtonHref}
                variant="primary"
                size={matchesMobile ? "s" : "m"}
                className="mr-4 min-w-[42px] font-medium sm:min-w-[72px]"
                underline={false}
              >
                {linkButtonLabel}
              </LinkButton>
            )}
            <Button
              variant="outline"
              onClick={handleLeftButtonClick}
              size={matchesMobile ? "s" : "m"}
              className={buttonClassName}
              textClassName="font-medium"
            >
              {leftButtonLabel}
            </Button>
            {rightButtonLabel && (
              <Button
                variant="primary"
                onClick={handleRightButtonClick}
                size={matchesMobile ? "s" : "m"}
                className={buttonClassName}
                textClassName="font-medium"
              >
                {rightButtonLabel}
              </Button>
            )}
          </nav>
        )}
      </div>
    </header>
  );
};

PublicHeader.displayName = "PublicHeader";

export default PublicHeader;
