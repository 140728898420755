export const KEY_DIFFERENCES_VIDEOS = {
  HIGH_TRUST: "https://ik.imagekit.io/8gceidoqid/landing-page/Circle_Dance_pOu0OtiJW.mp4",
  COLLABORATIVE: "https://ik.imagekit.io/8gceidoqid/landing-page/Collaborative_LXDA569Aw.mp4",
  NOISE_FREE: "https://ik.imagekit.io/8gceidoqid/landing-page/Organized_iCNlLgyf_.mp4",
};

export const HEADER_OFFSET = 56;

export const MAIN_SECTION_ID = "semblyMainSection";

// Key difference section id
export const TITLE_KEY_DIFF_SECTION_ID = "semblyKeyDiffTitleSection";
export const FIRST_KEY_DIFF_SECTION_ID = "semblyKeyDiffFirstSection";
export const SECOND_KEY_DIFF_SECTION_ID = "semblyKeyDiffSecondSection";
export const THIRD_KEY_DIFF_SECTION_ID = "semblyKeyDiffThirdSection";

// Privacy policy anchor id
export const INFORMATION_COLLECT_ID = "pp_informationCollect";
export const USE_YOUR_INFORMATION_ID = "pp_useYourInformation";
export const CONTROL_YOUR_PRIVACY_ID = "pp_controlYourPrivacy";
export const SHARE_YOUR_INFORMATION_ID = "pp_shareYourInformation";

// Public FAQ id
export const PUBLIC_FAQ = "public_faq";
