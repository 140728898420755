import type { GetServerSidePropsContext, NextPageContext } from "next";

import { API_ENDPOINT_GET_IMAGE_SNIPPET } from "@/src/constants/AppConstant";
import {
  APP_PATH,
  LANDSCAPE_IMAGE_HEIGHT,
  LANDSCAPE_IMAGE_WIDTH,
  SNIPPET_SIZE,
  SNIPPET_TYPE,
  SQUARE_IMAGE_HEIGHT,
  SQUARE_IMAGE_WIDTH,
} from "@/src/constants/ServerConstant";
import type { MetaContext } from "@/src/types/metatag";

const IMAGE_DIR_PATH = `${APP_PATH}/images`;

const USE_SERVER_GENERATED_IMAGE = true;
export const SEMBLY_BASE_URL_API = "https://sembly.com/api/app";

export const shouldUseSquareImage = (context: MetaContext) => {
  const regex = /whatsapp/g;
  const userAgent = context.userAgent.toLowerCase();
  return Boolean(userAgent.match(regex));
};

export const metatagHasSameKey = (metatag: JSX.Element, listOfMetatag: JSX.Element[]) => {
  let found = false;
  const keyMetatag = metatag.key;

  for (let i = 0; i < listOfMetatag.length && !found; i++) {
    const key = listOfMetatag[i].key;
    if (keyMetatag === key) {
      found = true;
    }
  }

  return found;
};

export type generateSnippetLinkParam = {
  snippetType: string;
  snippetSize?: string;
  baseUrl?: string;
  spaceUrl?: string;
  nodeId?: string;
  spaceId?: number;
};

export const generateSnippetLink = ({
  snippetType,
  snippetSize = SNIPPET_SIZE.LANDSCAPE,
  baseUrl,
  spaceUrl,
  nodeId,
  spaceId,
}: generateSnippetLinkParam) => {
  const endpointBaseUrl = baseUrl || process.env.REACT_APP_SERVER_PATH || SEMBLY_BASE_URL_API;
  return `${endpointBaseUrl}${API_ENDPOINT_GET_IMAGE_SNIPPET}?snippetType=${snippetType}&snippetSize=${snippetSize}${
    nodeId ? `&nodeId=${nodeId}` : ""
  }${spaceId ? `&spaceId=${spaceId}` : ""}${spaceUrl ? `&spaceUrl=${spaceUrl}` : ""}&t=${Math.floor(
    Date.now() / 1000,
  )}`;
};

export const generateSnippetType = (nodeId?: string, spaceUrl?: string) => {
  if (spaceUrl && !nodeId) return SNIPPET_TYPE.SPACE;
  if (nodeId) return SNIPPET_TYPE.NODE;
  return SNIPPET_TYPE.APP;
};

const generateTwitterCardMetaTag = () => {
  return { name: "twitter:card", content: "summary_large_image" };
};

export const generateTwitterTitle = (title: string) => {
  return { property: "twitter:title", content: title };
};

export const generateTwitterDescription = (description: string) => {
  return { property: "twitter:description", content: description };
};

const generateTwitterImage = (spaceUrl?: string, nodeId?: string, spaceId?: number) => {
  const snippetType = generateSnippetType(nodeId, spaceUrl);
  const snippetLink = generateSnippetLink({
    snippetType: snippetType,
    spaceUrl: spaceUrl,
    nodeId: nodeId,
    spaceId: spaceId,
  });
  return {
    property: "twitter:image",
    content: snippetLink,
  };
};

const generateTwitterMetatags = (
  title: string,
  description: string,
  spaceUrl?: string,
  nodeId?: string,
  spaceId?: number,
) => {
  const twitterMetaTagsProps = [
    generateTwitterTitle(title),
    generateTwitterDescription(description),
    generateTwitterImage(spaceUrl, nodeId, spaceId),
  ];
  const twitterMetaTags = twitterMetaTagsProps.map((data, idx) => {
    return <meta {...data} key={title + idx} />;
  });
  return twitterMetaTags;
};

const generateTitleMetaTag = (title: string) => {
  return { property: "og:title", content: title };
};

const generateDescriptionMetaTag = (description: string) => {
  return { property: "description", content: description };
};

const generateOGDescriptionMetaTag = (description: string) => {
  return { property: "og:description", content: description };
};

const generateImageMetaTag = (context: MetaContext, imageName = "img_snippet_default", imageExtension = ".png") => {
  return {
    property: "og:image",
    content: `${IMAGE_DIR_PATH}/${imageName}_${
      shouldUseSquareImage(context)
        ? `${SQUARE_IMAGE_WIDTH}_${SQUARE_IMAGE_HEIGHT}`
        : `${LANDSCAPE_IMAGE_WIDTH}_${LANDSCAPE_IMAGE_HEIGHT}`
    }${imageExtension}`,
  };
};

const generateContentImageMetaTag = (context: MetaContext, spaceUrl?: string, nodeId?: string, spaceId?: number) => {
  const snippetType = generateSnippetType(nodeId, spaceUrl);
  const snippetLink = generateSnippetLink({
    snippetType: snippetType,
    spaceUrl: spaceUrl,
    nodeId: nodeId,
    spaceId: spaceId,
  });
  if (USE_SERVER_GENERATED_IMAGE) {
    return {
      property: "og:image",
      content: snippetLink,
    };
  } else {
    return generateImageMetaTag(context);
  }
};

const generateImageWidthMetaTag = (context: MetaContext) => {
  return {
    property: "og:image:width",
    content: shouldUseSquareImage(context) ? SQUARE_IMAGE_WIDTH : LANDSCAPE_IMAGE_WIDTH,
  };
};

const generateImageHeightMetaTag = (context: MetaContext) => {
  return {
    property: "og:image:height",
    content: shouldUseSquareImage(context) ? SQUARE_IMAGE_HEIGHT : LANDSCAPE_IMAGE_HEIGHT,
  };
};

const generateUrlMetaTag = (context: MetaContext) => {
  return { property: "og:url", content: APP_PATH + context.path };
};

const generateRobotsMetaTag = () => {
  return { name: "robots", content: "noindex" };
};

export const generateMetaTags = (context: MetaContext, title: string, description: string) => {
  const metaTagProps = [
    generateTitleMetaTag(title),
    generateDescriptionMetaTag(description),
    generateOGDescriptionMetaTag(description),
    generateTwitterCardMetaTag(),
    generateImageWidthMetaTag(context),
    generateImageHeightMetaTag(context),
    generateUrlMetaTag(context),
  ];

  const titleTag = <title key="title">{title}</title>;
  const metaTags = metaTagProps.map((data, idx) => {
    return <meta {...data} key={idx} />;
  });

  return [titleTag, ...metaTags];

  // generateContentImageMetaTag();
};

type generateMetaWithImageContent = {
  context: MetaContext;
  title: string;
  description: string;
  imageAttr: {
    spaceUrl?: string;
    nodeId?: string;
    spaceId?: number;
    token?: string;
  };
};
export const generateMetaTagWithImageContent = ({
  context,
  title,
  description,
  imageAttr,
}: generateMetaWithImageContent) => {
  const listOfMetaElm = generateMetaTags(context, title, description);
  const constContentImageTag = generateContentImageMetaTag(
    context,
    imageAttr.spaceUrl,
    imageAttr.nodeId,
    imageAttr.spaceId,
  );
  listOfMetaElm.push(<meta {...constContentImageTag} key={listOfMetaElm.length} />);
  const twitterMetaTags = generateTwitterMetatags(
    title,
    description,
    imageAttr.spaceUrl,
    imageAttr.nodeId,
    imageAttr.spaceId,
  );

  twitterMetaTags.forEach((twitterTag: JSX.Element) => {
    // check if twitter metatags have the same key with previous metatags or not
    // to avoid overwrite metatags.
    const hasSameKey = metatagHasSameKey(twitterTag, listOfMetaElm);
    if (!hasSameKey) {
      listOfMetaElm.push(twitterTag);
    }
  });

  return listOfMetaElm;
};

export const generateNoIndexMetaTag = () => {
  const props = generateRobotsMetaTag();
  return <meta {...props} />;
};

export const generateMetaContext = (context: NextPageContext | GetServerSidePropsContext): MetaContext => {
  const req = context.req;
  const path = (context as NextPageContext).pathname || (context as GetServerSidePropsContext).resolvedUrl;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return {
    path,
    userAgent: userAgent || "",
  };
};
