import React, { useState } from "react";

type ModalType = "LOGIN" | "SIGN_UP" | "";

type Context = [ModalType, React.Dispatch<React.SetStateAction<ModalType>>] | undefined;

const HeaderModalContext = React.createContext<Context>(undefined);

function useHeaderModal() {
  const context = React.useContext(HeaderModalContext);

  if (!context) {
    throw new Error("useHeaderModal must be used within a HeaderModalProvider");
  }

  return context;
}

const HeaderModalProvider: React.FC<React.PropsWithChildren> = props => {
  const [context, setContext] = useState<ModalType>("");

  return <HeaderModalContext.Provider value={[context, setContext]} {...props} />;
};

export { useHeaderModal, HeaderModalProvider };
