import type { FC, RefObject } from "react";
import { createContext, useCallback, useContext, useRef } from "react";

import { HEADER_OFFSET, TITLE_KEY_DIFF_SECTION_ID } from "@/src/components/container/LandingPageContainer/config";

type ScrollbarRefType = RefObject<HTMLDivElement> | null;

interface ContextType {
  pageViewWrapperRef: ScrollbarRefType;
  scrollToSemblyDifferenceTitle: () => void;
}

const PublicPageContext = createContext<ContextType>({
  pageViewWrapperRef: null,
  scrollToSemblyDifferenceTitle: () => {},
});

export function PublicPageWrapperContext(props: { children: React.ReactNode }) {
  const { children } = props;
  const pageViewWrapperRef = useRef<HTMLDivElement>(null);

  const scrollToSemblyDifferenceTitle = useCallback(() => {
    const titleKeySectionElm = document.getElementById(TITLE_KEY_DIFF_SECTION_ID);
    if (titleKeySectionElm) {
      const scrollTop =
        titleKeySectionElm.getBoundingClientRect().top + (pageViewWrapperRef?.current?.scrollTop || 0) - HEADER_OFFSET;
      pageViewWrapperRef?.current?.scrollTo({ top: scrollTop, behavior: "smooth" });
    }
  }, []);

  return (
    <PublicPageContext.Provider value={{ pageViewWrapperRef, scrollToSemblyDifferenceTitle }}>
      {children}
    </PublicPageContext.Provider>
  );
}

export function usePublicPageWrapperContext() {
  const context = useContext(PublicPageContext);
  if (typeof context === "undefined") {
    throw new Error("usePublicPageWrapperContext must be used within a PublicPageWrapperContext");
  }
  return context;
}

export function withPublicPageContext(Component: FC) {
  const withPublicPageContextComponent = (props: { [key: string]: unknown }) => (
    <PublicPageWrapperContext>
      <Component {...props} />
    </PublicPageWrapperContext>
  );
  withPublicPageContextComponent.displayName = `withPublicPageContext(${Component.displayName || Component.name})`;

  return withPublicPageContextComponent;
}
